/* ------------------------------------------------
- Ici, les helpers
------------------------------------------------ */
/**
 * Automatically generate text color utilities class for each of our
 * defined contrib colors.
 *
 * <div class="color--green"></div>
 */

@if (variable-exists(contrib-colors)) {
    @each $color-name, $color-value in $contrib-colors {
        .color--#{$color-name} {
            color: $color-value !important;
        }
    }
}

/**
* Automatically generate background-color utilities class for each of our
 * defined contrib colors.
 *
 * <div class="bg--green"></div>
 */

@if (variable-exists(contrib-colors)) {
    @each $color-name, $color-value in $contrib-colors {
        .bg--#{$color-name} {
            background-color: $color-value !important;
        }
    }
}

.white-color {
    color: $white;
}

.last-child-no-margin {
    @include last-child-no-margin;
}

.bk-type-txt {
    @include bk-type-txt;
}

.bk-type,
%bk-type {
    @include bk-type;
}

.bk-type-m {
    @include bk-type(m);
}

.bk-type-l {
    @include bk-type(l);
}

.pt-0 {
    padding-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-20 {
    margin-bottom: rem(20px) !important;
}

.mb-xl {
    margin-bottom: rem($spacer-xl) !important;
}

@if (variable-exists(spacers)) {
    @each $spacer-name, $spacer-value in $spacers {
        .mb-#{$spacer-name} {
            margin-bottom: $spacer-value !important;
        }
        .mt-#{$spacer-name} {
            margin-top: $spacer-value !important;
        }
    }
}

.small-text {
    font-size: rem(14px);
}

.big-text {
    font-size: rem(24px);
}

.ta-l {
    text-align: left !important;
}

.ta-r {
    text-align: right !important;
}

.ta-c {
    text-align: center;
}

.float-l {
    float: left;
}

.float-r {
    float: right;
}

.uppercase {
    text-transform: uppercase;
}

.fw-bold {
    font-weight: bold;
}

.fw-normal {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500;
}

.ws-nowrap {
    white-space: nowrap;
}

.out,
%out {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
    width: 0;
}

.mob-hidden {
    @include media('<desktop') {
        display: none !important;
    }
}

.mob-visible {
    display: none;

    @include media('<desktop') {
        display: block;
    }
}

.mob-block {
    @include media('<desktop') {
        display: block;
    }
}

.lk-no-underline {
    text-decoration: none;

    a {
        text-decoration: none;
    }
}

.text-info {
    padding: rem(5px) rem(10px);
    background-color: $color-info;
    border: 1px solid $color-alert;
    color: $black;
}

.text-error {
    color: $color-error;
}

.text-success {
    color: $color-success;
}

.bullet-list,
%bullet-list {
    text-align: left;

    li {
        margin-bottom: rem($spacer-xxs);
        padding-left: rem($spacer-s);
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: rem($spacer-xs);
            transform: translate(0, -50%);
            width: rem(4px);
            height: rem(4px);
            color: inherit;
            background-color: currentColor;
            border-radius: 50%;
        }

        ul,
        ol {
            margin-bottom: rem($spacer-xs);

            li {
                font-size: rem(14px);
            }
        }

        ul {
            margin-bottom: rem($spacer-xs);

            li {
                &::before {
                    width: rem(4px);
                    height: rem(4px);
                    top: rem(10px);
                }
            }
        }
    }
}

@include dark-bg {
    .bullet-list {
        li {
            &:before {
                color: inherit;
            }
        }
    }
}

.ol-list,
%ol-list {
    text-align: left;
    counter-reset: ol-counter;

    >li {
        margin: 0 0 rem($spacer-xxs) rem($spacer-xs);
        padding-left: rem($spacer-xs);
        position: relative;

        &:before {
            content: counter(ol-counter)'.';
            counter-increment: ol-counter;
            position: absolute;
            right: 100%;
            top: 0;
            color: $color-primary;
            display: inline-block;
            padding-right: rem(3px);
            font-weight: bold;
        }
    }

    ul,
    ol {
        margin-bottom: rem($spacer-xs);

        li {
            font-size: rem(14px);
        }
    }

    ol {
        counter-reset: ol-counter-bis;

        li {
            counter-increment: ol-counter-bis;

            &:before {
                content: counter(ol-counter)'.'counter(ol-counter-bis)'.';
            }
        }
    }
}

.square-list {
    li {
        padding-left: rem(15px);
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: rem(8px);
            width: rem(6px);
            border-top: rem(6px) solid currentColor;
        }

        li {
            padding-left: rem(10px);

            &::before {
                width: rem(3px);
                border-width: rem(3px);
            }
        }
    }
}

.bullet-list,
.square-list {
    &.-secondary {
        li {
            &::before {
                color: $color-secondary;
            }
        }
    }
}

.inline-list {
    letter-spacing: -.31em;

    > li {
        display: inline-block;
        margin: 0 rem($spacer-xs) rem($spacer-xs) 0;
        vertical-align: middle;
        letter-spacing: normal;

        &:last-child {
            margin-right: 0;
        }
    }
}

.no-styled-list,
%no-styled-list {
    margin-bottom: 0;

    li {
        margin: 0;
        padding: 0;
        position: static;

        &:before,
        &:after {
            display: none;
        }
    }
}

.of-cover {
    @include object-fit(cover);
}

.of-contain {
    @include object-fit(contain);
}

.hidden {
    display: none !important;
}

.hidden-from--desktop {
    @include media('>=desktop') {
        display: none !important;
    }
}

.hidden-from--medium {
    @include media('>=medium') {
        display: none !important;
    }
}

.hidden-from--tablet {
    @include media('>=tablet') {
        display: none !important;
    }
}

.hidden-from--mobile {
    @include media('>=mobile') {
        display: none !important;
    }
}

.hidden-until--desktop {
    @include media('<desktop') {
        display: none !important;
    }
}

.hidden-until--medium {
    @include media('<medium') {
        display: none !important;
    }
}

.-hidden-until--tablet {
    @include media('<tablet') {
        display: none !important;
    }
}

.-hidden-until--mobile {
    @include media('<mobile') {
        display: none !important;
    }
}

.wysiwyg-content {
    >* {
        &:first-child {
            margin-top: 0;
            padding-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
}

.paragraph {
    max-width: rem($sc-max-width);
    margin: rem($spacer-l) auto;
    padding: 0 rem($sc-x-padding);

    .site-content & {
        max-width: none;
        padding: 0;
    }

    @include media('<medium') {
        padding: 0 rem($sc-x-padding-s);
    }

    @include media('<tablet') {
        margin: rem($spacer-m) auto;
    }
}

.reduced-content {
    max-width: rem(795px) !important;
    margin-left: auto;
    margin-right: auto;

    &-xs {
        max-width: rem(480px) !important;
    }
}

%centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

%middle {
    display: flex;
    align-items: center;
}

.tag {
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.3;
    text-align: center;
    padding: .4rem .6rem;
    border-radius: $global-radius;
    background-color: var(--tag-bg);
    color: var(--tag-color);
}

.section {
    margin: 8rem 0;

    @include media('>=medium') {
        margin: 9.6rem 0;
    }
}
