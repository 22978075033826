$d: '.header-search';

#{$d} {
    position: absolute;
    top: var(--header-height);
    left: 0;
    width: 100%;
    z-index: 2;

    &__inner {
        height: calc(100vh - var(--header-height)); //TODO: check for iStuff. this have pb with vh
        padding: 7.2rem 0 8.8rem;
    }

    .btn.visible-on-focus {
        position: absolute;
        right: -50000px;

        &:focus {
            right: rem(20px);
            top: rem(20px);
        }
    }
}
