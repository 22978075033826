.bk-homepage-hero {
    --hero-offset: -29rem;
    position: relative;
    padding: 11.5rem 0 0;

    &--img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: var(--hero-offset);
        z-index: -1;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;

            @include media('<medium') {
                object-position: bottom;
            }
        }

        @include media('<tablet') {
            top: -20vw;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(180deg, rgba(250, 250, 250, 0) 65.12%, rgba(250, 250, 250, .4443) 70.71%, rgba(250, 250, 250, .7485) 77.27%, #FAFAFA 100%);
        }
    }

    &--title {
        font-weight: 500;
        margin: .8rem 0;
        font-size: 2.4rem;

        @include media('>=medium') {
            font-size: 4rem;
        }

        .custom-bg {
            position: relative;
            z-index: 1;

            .age {
                color: #fff;
                transform: rotate(-8deg);
                position: absolute;
                font-size: 1.8rem;
                line-height: 2.9rem;
                padding: 0 .6rem;
                background: $color-primary;
                border-radius: .8rem;
                bottom: -2.9rem;
                right: -4rem;

                .small {
                    font-size: .6em;
                }

                @include media('>=mobile') {
                    right: -6rem;
                    bottom: -2.6rem;
                }

                @include media('>=medium') {
                    font-size: 2.8rem;
                    line-height: 4.4rem;
                    bottom: -3.6rem;
                    right: -8rem;
                }
            }

            &:before {
                content: '';
                left: -1.9rem;
                right: -1.5rem;
                top: 50%;
                transform: translateY(-50%);
                padding-top: 17%;
                position: absolute;
                z-index: -1;
                background: {
                    image: url('../images/banner-title.svg');
                    repeat: no-repeat;
                    size: contain;
                    position: center;
                };
            }
        }
    }

    &--content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        text-align: center;
        min-height: 53.8rem;

        @include media('>=medium') {
            align-items: flex-start;
            text-align: left;
        }

        .btn {
            @include media('<medium') {
                span {
                    display: none;
                }
            }

            @include media('>=medium') {
                width: 34.3rem;
            }

            & + .btn {
                margin-top: 1.6rem;
            }
        }
    }

    @include media('<medium') {
        --hero-offset: calc(-42rem + ((100rem - 100vw) * .6));
    }
}
