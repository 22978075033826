.page-avantage,
.page-advice-and-inspiration {
    &--top {
        display: none;

        @include media('>medium') {
            display: block;
            box-shadow: 0 .2rem .4rem rgba(101, 101, 101, .06);
            padding: 3.2rem 0;
            position: relative;

            .like-h1 {
                margin: 0;
            }

            p {
                margin: 0;
                font-size: 1.8rem;
                color: $dark-grey-2;
            }
        }

        .filters {
            display: flex;
            padding: 2.4rem 0 0;
            position: relative;

            @include media('<medium') {
                margin: 0 -.8rem;

                .btn {
                    width: 100%;
                }
            }

            .btn {
                padding: .8rem 1.2rem;
            }

            .drop {
                &down-menu {
                    display: none;

                    &.show {
                        display: block;
                        z-index: 1;
                    }
                }

                &box {
                    background: $white;
                    box-shadow: 0 .1rem .8rem rgba(101, 101, 101, .12);
                    border-radius: .8rem;
                    padding: 1.6rem;
                    z-index: 1001;

                    position: fixed;
                    left: 1.6rem;
                    width: calc(100% - 3.2rem);
                    top: 50%;
                    transform: translateY(-50%);

                    @include media('>=medium') {
                        position: absolute;
                        top: calc(100% + .8rem);
                        left: 0;
                        width: auto;
                        transform: none;
                        padding: 2.4rem;
                    }

                    @include media('<medium') {
                        .like-h2 {
                            font-size: 1.8rem;
                        }
                    }

                    .close {
                        position: absolute;
                        right: 1.6rem;
                        top: 1.6rem;
                        font-size: 2.2rem;
                        line-height: 1.4rem;
                        padding: 0;
                        border: 0;
                        background-color: transparent;
                        color: $dark-grey-2;
                        height: 3.08rem;

                        @include media('>=medium') {
                            right: 2.4rem;
                            top: 2.4rem;
                        }
                    }

                    header {
                        margin-bottom: 2.4rem;
                    }

                    .btn--secondary {
                        @include media('>=medium') {
                            max-width: 50%;
                        }
                    }

                    .category {
                        ul {
                            margin: -1.2rem 0;

                            li {
                                margin: 1.2rem 0;
                            }
                        }

                        @include media('>=medium') {
                            width: 58.8rem;

                            ul {
                                display: flex;
                                flex-wrap: wrap;

                                li {
                                    flex: 50% 0 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &--list {
        background-color: #E5E5E5;
        padding: 2.4rem 0 4.8rem;

        .card {
            height: calc(100% - 2.4rem);

            &--body {
                flex: 100% 1 1;
            }
        }
    }

    &__article {
        display: flex;
        flex-direction: column;

        .info {
            order: -1;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            font-weight: 500;
            font-size: 1.4rem;
            margin: 0 0 1.7rem;
            flex-direction: row;

            @include media('>=medium') {
                font-size: 2rem;
            }

            .dates {
                .update {
                    display: block;
                    font-size: .79em;
                    color: $dark-grey-2;
                }

                .publish {
                    font-size: .64em;
                    color: $dark-grey;
                }
            }

            .share {
                font-size: 1em;

                a {
                    @extend %middle;
                    text-decoration: none;
                    padding: .8rem;

                    .icon {
                        margin-left: .5rem;
                    }
                }
            }

            .author {
                @extend %middle;
                margin-top: 2rem;
                flex: 100% 0 0;
                font-size: .79em;

                .avatar {
                    margin-right: .5rem;
                    width: 1.27em;
                    height: 1.27em;
                }
            }
        }
    }
}
