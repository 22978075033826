$d: '.btn-header';

#{$d} {
    background: none;
    border: none;
    width: 3.2rem;
    height: 3.2rem;
    color: inherit;
    font-size: 2rem;
    margin-left: 2.4rem;

    @extend %centered;

    @include media('<medium') {
        margin-left: 0;
    }

    .icon-close {
        font-size: 1.45em;
        display: none;
    }

    &[aria-expanded='true'] {
        [class*='icon-'] {
            display: none;
        }

        .icon-close {
            display: block;
        }
    }

    &__user {
        text-decoration: none;
        order: 3;
        font-size: 3rem;

        @include media('<medium') {
            display: none;
        }
    }

    &__search {
        order: 2;

        &:not(.collapsed) {
            & ~ .section-title {
                font-size: 0;

                &:before {
                    content: 'Recherche';
                    font-size: 2.2rem;
                }
            }
        }
    }
}
