$icomoon-font-family: 'icomoon' !default;
$icomoon-font-path: 'fonts' !default;

$icon-calendar-fill: '\e918';
$icon-categories: '\e916';
$icon-categories-fill: '\e917';
$icon-geolocation: '\e919';
$icon-megaphone: '\e900';
$icon-document: '\e915';
$icon-qr-code: '\e911';
$icon-player: '\e943';
$icon-player-full: '\e944';
$icon-checked: '\e938';
$icon-close: '\e935';
$icon-arrow-b: '\e901';
$icon-arrow-first: '\e902';
$icon-arrow-l: '\e903';
$icon-arrow-last: '\e904';
$icon-arrow-line-b: '\e905';
$icon-arrow-line-l: '\e906';
$icon-arrow-line-r: '\e907';
$icon-arrow-line-t: '\e908';
$icon-arrow-r: '\e909';
$icon-arrow-t: '\e90a';
$icon-linkedin: '\e921';
$icon-play-circle-line: '\e926';
$icon-rss: '\e92a';
$icon-twitter: '\e92e';
$icon-youtube: '\e92f';
$icon-facebook: '\e932';
$icon-instagram: '\e933';
$icon-share: '\e934';
$icon-calendar: '\e90d';
$icon-calendar-2: '\e90e';
$icon-clock: '\e90f';
$icon-map-marker: '\e910';
$icon-like: '\e912';
$icon-map-marker-fill: '\e914';
$icon-promotion: '\e913';
$icon-search: '\e90b';
$icon-user: '\e90c';
$icon-loading: '\e982';
$icon-eye: '\e9ce';
$icon-eye-blocked: '\e9d1';

