$c: '.main-header';

#{$c} {
    background-color: var(--header-bg);
    color: var(--header-color);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 11;

    .wrapper-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: var(--header-height);
    }

    .logo {
        display: block;
        height: auto;
        max-width: rem(40px);
        order: 1;

        @include media('>=medium') {
            max-width: rem(148px);

            order: -1;
        }

        img {
            display: block;
            height: 100%;

            @include media('>=medium') {
                height: auto;
            }
        }
    }

    .section-title {
        @include media('<medium') {
            order: 1;

            & ~ .logo {
                display: none;
            }
        }

        @include media('>=medium') {
            //@extend %out;

            position: absolute !important;
            clip: rect(1px, 1px, 1px, 1px);
            overflow: hidden;
            height: 1px;
            width: 0;
        }
    }

    /**
    * Nav
     */
    &__main-nav {
        position: absolute;
        overflow: auto;
        top: var(--header-height);
        left: 0;
        width: 100%;
        background-color: rgba(27, 27, 27, .95); //--header-bg //TODO: check some func for color
        z-index: 2;

        @include media('>=medium') {
            display: block !important;
            position: static;
            overflow: inherit;
            flex: 100% 1 1;
            background: inherit;
        }

        &.collapsing {
            overflow: hidden;
        }

        &__inner {
            @include media('<medium') {
                height: calc(100vh - var(--header-height)); //TODO: check for iStuff. this have pb with vh

                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }

        &__user-menu {
            padding: 0 2.4rem;

            @include media('>=medium') {
                display: none;
            }

            .logo {
                margin: 2rem auto 4rem;
                width: 14.8rem;
                max-width: none;
            }

            .btn {
                width: 100%;
                margin-top: 1.6rem;

                &--tertiary {
                    color: $darkest-grey;
                }
            }
        }
    }

    .nav {
        @extend %no-styled-list;
        font-family: $headings-fonts;

        @include media('>=medium') {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        &-link {
            padding: .6rem 1.2rem;
            position: relative;
            text-decoration: none;
            line-height: 1.2;

            @include hover {
                color: inherit;

                &:before {
                    width: calc(100% - 2.4rem);
                }
            }

            @include media('<medium') {
                font-size: 1.8rem;
                font-weight: 500;
                border-bottom: .1rem solid var(--base-dark-grey);
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1.7rem 2.4rem;

                &:first-child {
                    border-top: .1rem solid var(--base-dark-grey);
                }

                &:after {
                    content: $icon-arrow-r;
                    font-family: $icomoon-font-family;
                    font-size: 1.4em;
                }
            }

            @include media('>=medium') {
                font-size: 1.8rem;

                &:before {
                    content: '';
                    height: .2rem;
                    width: 0;
                    background-color: var(--base-secondary);
                    transition-duration: var(--duration);
                    position: absolute;
                    left: 1.2rem;
                    bottom: 0;
                }
            }
        }
    }
}
