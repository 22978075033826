.bk-hero {
    position: relative;
    margin: 1.6rem 0 2.4rem;

    @include media('>=tablet') {
        margin: 4rem 0 4.8rem;
    }

    .tag {
        position: absolute;
        top: .8rem;
        left: .8rem;

        @include media('>=tablet') {
            left: 1.6rem;
            top: 1.6rem;
        }
    }

    &--img {
        img {
            object-fit: cover;
            width: 100%;
            height: 18rem;
            border-radius: 1.2rem;
            display: block;

            @include media('>=tablet') {
                height: 32rem;
            }
        }
    }
}
