$d: '.btn-menu';


#{$d} {
    $h: .2rem; //height for burger's line
    $d: .2s; //duration animation
    $f: linear; //timing func

    background: none;
    border: 0;
    color: inherit;
    width: 3.2rem;
    height: 3.2rem;
    padding: .8rem .6rem;

    @include media('>=medium') {
        display: none;
    }

    &__hamburger {
        position: relative;
        display: block;
        height: 100%;

        &-inner {
            border-bottom: $h solid;
            width: 100%;
            border-radius: $h / 2;
            display: block;
            position: absolute;
            margin-top: -$h / 2;

            &:nth-child(1) {
                top: $h / 2;
                transition: top ($d / 2) $f ($d / 2), transform ($d / 2) $f 0s;
            }

            &:nth-child(2) {
                top: 50%;
                transition: top 0s $f ($d / 2), opacity ($d / 2) step-end 0s;
            }

            &:nth-child(3) {
                top: calc(100% - #{$h / 2});
                transition: top ($d / 2) $f ($d / 2), transform ($d / 2) $f 0s;
            }

            [aria-expanded='true'] & {
                top: 50%;
                //minor manipulation for close button like icon
                border-bottom: .3rem solid;
                border-radius: .15rem;

                &:nth-child(1) {
                    transform: rotate(45deg);
                    transition: top ($d / 2) $f 0s, transform ($d / 2) $f ($d / 2);
                }

                &:nth-child(2) {
                    opacity: 0;
                    transition: top ($d / 2) $f 0s, opacity 0s step-end ($d / 2);
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                    transition: top ($d / 2) $f 0s, transform ($d / 2) $f ($d / 2);
                }
            }
        }
    }

    /* &[aria-expanded='true'] {
        #{$d}__hamburger-inner {
            transition-delay: .12s;
            transition-timing-function: cubic-bezier(.215,.61,.355,1);
            transform: rotate(45deg);
            background-color: $grey;

            &:before {
                top: 0;
                transition: top 75ms ease,opacity 75ms ease .12s;
                opacity: 0;
                background-color: $grey;
            }

            &:after {
                bottom: 0;
                transition: bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s;
                transform: rotate(-90deg);
                background-color: $grey;
            }
        }
    }

    &__hamburger {
        display: block;
        overflow: visible;
        height: rem(22px);
        margin: 0;
        cursor: pointer;
        transition-timing-function: linear;
        transition-duration: .15s;
        transition-property: opacity,filter;
        text-transform: none;
        color: inherit;
        border: 0;
        background-color: transparent;
    }

    &__hamburger-box {
        position: relative;
        display: inline-block;
        width: rem(32px);
        height: rem(22px);
    }

    &__hamburger-inner {
        position: absolute;
        display: block;
        top: 50%;
        margin-top: rem(-2px);
        width: rem(32px);
        height: rem(2px);
        transition-property: transform;
        background-color: $grey;
        transition-timing-function: cubic-bezier(.55,.055,.675,.19);
        transition-duration: 75ms;

        &:before {
            content: '';
            display: block;
            position: absolute;
            width: rem(32px);
            height: rem(2px);
            top: rem(-10px);
            background-color: $grey;
            transition: top 75ms ease .12s, opacity 75ms ease;
            transition-timing-function: ease;
            transition-duration: .15s;
            transition-property: transform;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            width: rem(32px);
            height: rem(2px);
            bottom: rem(-10px);
            background-color: $grey;
            transition: bottom 75ms ease .12s, transform 75ms cubic-bezier(.55, .055, .675, .19);
            transition-timing-function: ease;
            transition-duration: .15s;
            transition-property: transform;
        }
    } */
}
