/*
 * vars.scss
 */

/* ============================== */
/* Colors */
/* ============================== */
$black: #000;
$darkest-grey: #1B1B1B;
$dark-grey-2: #464646;
$dark-grey: #656565;
$grey: #999;//-
$light-grey: #E8E8E8;
$lightest-grey: #FAFAFA;
$white: #fff;
$text-placeholder: #656565;

$yellow: #FFFF00;
$blue: #503FFE;
$dark-blue: #13235b;
$light-blue: #EDEBFF;
$violet: #AC47D7;
$rose: #EA064E;
$orange: #D93F1C;

$color-text: $darkest-grey;
$color-info: lighten($yellow, 10%);
$color-alert: darken($yellow, 10%);
$color-error: #c00;
$color-success: #007f00;
$color-placeholder: $text-placeholder;
$color-link-hover: $grey;
$color-link-hover-dark-bg: $light-grey;
$color-border: $grey;

/// <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50"><rect fill="#00F" x="0" y="0" width="100%" height="100%"/></svg>
/// @group colors
$color-primary: #1B1B1B;
/// <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50"><rect fill="#ffc617" x="0" y="0" width="100%" height="100%"/></svg>
/// @group colors
$color-secondary: $yellow;
/// <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50"><rect fill="#3a8f3a" x="0" y="0" width="100%" height="100%"/></svg>
/// @group colors
$color-tertiary: $blue;

$color-focus: $color-primary;
$color-text-selection: $color-primary;

// To generate bg--color classes
$contrib-colors: (
    'black': $darkest-grey,
    'dark-grey': $dark-grey,
    'light': $lightest-grey,
    'primary': $color-primary,
    'secondary': $color-secondary,
    'tertiary': $color-tertiary
);

$dark-backgrounds: '.bg--primary, .bg--black';

/* ============================== */
/* Spacers */
/* ============================== */

/// @group spacing
$spacer-unit: 8px;
/// 8px
/// @group spacing
$spacer-xxs: 1 * $spacer-unit;
/// 12px
/// @group spacing
$spacer-xs: 1.5 * $spacer-unit;
/// 16px
/// @group spacing
$spacer-s: 2 * $spacer-unit;
/// 24px
/// @group spacing
$spacer-m: 3 * $spacer-unit;
/// 32px
/// @group spacing
$spacer-m2: 4 * $spacer-unit;
/// 48px
/// @group spacing
$spacer-l: 6 * $spacer-unit;
/// 56px
/// @group spacing
$spacer-xl: 7 * $spacer-unit;
/// 64px
/// @group spacing
$spacer-xxl: 8 * $spacer-unit;

// To generate helper classes
$spacers: (
    xxs: $spacer-xxs,
    xs: $spacer-xs,
    s: $spacer-s,
    m: $spacer-m,
    m2: $spacer-m2,
    l: $spacer-l,
    xl: $spacer-xl,
    xxl: $spacer-xxl,
);

/* ============================== */
/* Fonts
/* ============================== */
$root-font-size-ie11: calc(1em * .625) !default; /* 10px, IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
$root-font-size: 10px !default; /* 10px, IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
/// @group typography
$base-font-size: 16px !default;
/// @group typography
$default-fonts: Roboto, Arial, sans-serif;
/// @group typography
$headings-fonts: 'Readex Pro', Arial, sans-serif;

/* ============================== */
/* Breakpoints
/* ============================== */
///@group responsive
$large: 1560px;
///@group responsive
$desktop: 1366px;
///@group responsive
$medium: 1024px;
///@group responsive
$tablet: 768px;
///@group responsive
$mobile: 480px;

/// variable utilisée par le module "include media"
/// @group responsive
/// @link https://eduardoboucas.github.io/include-media/
/// @prop {value in px} mobile [480px]
/// @prop {value in px} tablet [768px]
/// @prop {value in px} medium [1024px]
/// @prop {value in px} desktop [1366px]
/// @prop {value in px} large [1560px]
$breakpoints: (mobile: $mobile, tablet: $tablet, medium: $medium, desktop: $desktop, large: $large);

$gutter-w: $spacer-m;

/* ============================== */
/* Avalanche grid system
/* ============================== */

/// Espace entre les colonnes
/// @group grid
/// @link http://colourgarden.github.io/avalanche/
$av-gutter: $gutter-w;

/// colonnes disponibles
/// @group grid
/// @link http://colourgarden.github.io/avalanche/
$av-widths: (
    1,
    2,
    3,
    4,
    5,
    6,
    12
);

/// Breakpoints
/// @group grid
/// @link http://colourgarden.github.io/avalanche/
$av-breakpoints: (
    'mobile': 'screen and (min-width: #{$mobile})',
    'tablet': 'screen and (min-width: #{$tablet})',
    'medium': 'screen and (min-width: #{$medium})',
    'desktop': 'screen and (min-width: #{$desktop})'
);

$av-enable-grid-center: true;
$av-enable-grid-right: true;
$av-enable-grid-cell-center: true;
$av-enable-grid-flush: true;
$av-enable-grid-bottom: true;
$av-enable-grid-middle: true;
$av-enable-grid-small: true;
$av-enable-grid-large: true;
$av-enable-grid-huge: true;
$av-enable-grid-rev: true;

// Icomoon
$icomoon-font-path: './../fonts/icomoon' !default;

//Site content
/// Site content max-width
///@group layout
$sc-max-width: 1240px;
/// Site content x-padding
///@group layout
$sc-x-padding: 20px;
/// Site content x-padding
///@group layout
$sc-x-padding-s: 20px;

// button radius
$btn-radius: 12px;

// global radius
$global-radius: .6rem;
$duration: .3s;

:root {
    --base-darkest-grey: #{$darkest-grey};
    --base-dark-grey: #{$dark-grey};
    --base-white: #{$white};
    --base-primary: #{$color-primary};
    --base-secondary: #{$color-secondary};

    --duration: #{$duration};
    --focus-color: #{$color-primary};
    --focus-contrast: #{$color-secondary};

    --header-height: 8rem;
    --header-bg: var(--base-darkest-grey);
    --header-color: var(--base-white);

    --tag-bg: #{$color-tertiary};
    --tag-color: var(--base-white);
}

[class*='bg--'] {
    --focus-color: #{$color-primary};
    --focus-contrast: #{$color-secondary};
}

#{$dark-backgrounds} {
    --focus-color: #{$color-secondary};
    --focus-contrast: #{$color-primary};
}

.page-immanquable {
    --unmissable-color: #{$violet};
}
