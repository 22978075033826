/*
 * Embedded Fonts
 */

/* Icomoon */
$icomoon-font-path: './../fonts/icomoon/fonts';

@import './../../fonts/icomoon/style';
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;500;600&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

// Use font-face mixin :

// @include font-face('myFont', 'myFont-Regular_Bold', 700);
// @include font-face('myFont', 'myFont-Regular_Italic', 400, italic);

// @include font-face('myFont', 'myFont-Medium', 500);
// @include font-face('myFont', 'myFont-Medium_Italic', 500, italic);

// @include font-face('myFont', 'myFont-Bold', 700);
// @include font-face('myFont', 'myFont-Bold_Italic', 700, italic);
