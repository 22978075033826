/*
 * Typography
 */


/* Default font */
html {
    font-family: $default-fonts;
    font-size: $root-font-size-ie11; /* IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
    line-height: 1.5;
}

body {
    font-size: $base-font-size;
}

%headings {
    @include media('>=tablet') {
        margin: rem($spacer-m2) 0;
    }

    font-family: $headings-fonts;
    font-weight: 400;
    margin: rem($spacer-m) 0;
    line-height: 1.4;
    color: inherit;

    &:first-child {
        margin-top: 0 !important;
    }
}

h1,
.like-h1 {
    @extend %headings;
    font-size: 3rem;

    @include media('>=medium') {
        font-size: 3rem;
    }
}

h2,
.like-h2,
%like-h2 {
    @extend %headings;
    font-size: 2.2rem;
}

h3,
.like-h3,
%like-h3 {
    @extend %headings;
    font-size: 1.8rem;
    font-weight: 500;

    @include media('>=medium') {
        //Montserrat bold ??
    }
}

h4,
.like-h4 ,
%like-h4 {
    @extend %headings;
    font-size: 1.6rem;
}

h5,
.like-h5 {
    @extend %headings;
    font-size: 1.4rem;//?
}

h6,
.like-h6 {
    @extend %headings;
    font-size: 1.2rem;//?
}

a {
    display: inline-block;
    color: inherit;
    text-decoration: underline;
    transition: .2s;

    &:hover {
        color: $black;
    }
}

@include dark-bg {
    a {
        color: $white;

        &:hover {
            color: $color-link-hover-dark-bg;
        }
    }
}

ul,
ol,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul {
    @extend %bullet-list;
    @include bk-type;
}

ol {
    @extend %ol-list;
    @include bk-type;
}

img {
    height: auto;
    max-width: 100%;
}

ul,
ol,
p,
fieldset {
    @include bk-type-txt;

    &:first-child {
        margin-top: 0;
    }
}

hr {
    border: 0;
    border-top: 1px solid $dark-grey;
    margin: rem($spacer-m) 0;
}

pre {
    display: block;
    border: 1px solid #690;
    margin: 0 0 1.5em;

    &[class*='language-'] {
        margin: 0 0 1.5em !important;
    }
}

fieldset {
    legend {
        @extend %like-h3;
    }
}

blockquote,
q {
    padding: 10px;
    margin: 0;
    font-style: italic;
    background-color: $white;
    border: 1px solid $dark-grey;

    &::before,
    &::after {
        content: '“';
        font-size: larger;
    }

    &::after {
        content: '”';
    }
}

.placeholder {
    color: $color-placeholder;

    .parsley-error & {
        color: $color-error;
    }
}

::-webkit-input-placeholder {
    color: $color-placeholder;

    .parsley-error & {
        color: $color-error;
    }
}

:-moz-placeholder {
    color: $color-placeholder;

    .parsley-error & {
        color: $color-error;
    }
}

::-moz-placeholder {
    color: $color-placeholder;

    .parsley-error & {
        color: $color-error;
    }
}

:-ms-input-placeholder {
    color: $color-placeholder;

    .parsley-error & {
        color: $color-error;
    }
}


// outline only on keyboard focus (with whatinput plugin)
a,
input,
select,
textarea,
button,
[tabindex] {
    &:focus {
        outline: none;

        [data-whatinput='keyboard'] & {
            @include outline;
        }
    }
}

@include dark-bg {
    color: $white;
}

::selection {
    background-color: var(--focus-color);
    color: var(--focus-contrast);
}
